<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.876 5.71842L8.98143 2.57031L7.08686 5.71842C6.80761 6.18245 6.35211 6.51338 5.82451 6.63558L2.24503 7.46459L4.6536 10.2392C5.00862 10.6482 5.18261 11.1837 5.13578 11.7232L4.8181 15.3837L8.20125 13.9504C8.69991 13.7392 9.26294 13.7392 9.7616 13.9504L13.1447 15.3837L12.8271 11.7232C12.7802 11.1837 12.9542 10.6482 13.3092 10.2392L15.7178 7.46459L12.1383 6.63558C11.6107 6.51338 11.1552 6.18245 10.876 5.71842ZM9.83823 2.05468C9.44957 1.40886 8.51328 1.40886 8.12462 2.05468L6.23006 5.20279C6.09043 5.4348 5.86268 5.60027 5.59888 5.66136L2.0194 6.49038C1.28508 6.66045 0.995751 7.55092 1.48986 8.12013L3.89844 10.8948C4.07595 11.0993 4.16294 11.367 4.13953 11.6368L3.82185 15.2972C3.75668 16.0482 4.51415 16.5985 5.20819 16.3045L8.59134 14.8712C8.84067 14.7656 9.12218 14.7656 9.37151 14.8712L12.7547 16.3045C13.4487 16.5985 14.2062 16.0482 14.141 15.2972L13.8233 11.6368C13.7999 11.367 13.8869 11.0993 14.0644 10.8948L16.473 8.12013C16.9671 7.55092 16.6778 6.66045 15.9435 6.49038L12.364 5.66136C12.1002 5.60027 11.8724 5.4348 11.7328 5.20279L9.83823 2.05468Z"
      fill="#525252"
    />
  </svg>
</template>
