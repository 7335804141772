<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.17127 8.56998L9.00345 3.38582C9.57178 2.88064 10.4282 2.88064 10.9965 3.38582L16.8287 8.56998C17.2557 8.94951 17.5 9.49352 17.5 10.0648V15.5C17.5 16.6046 16.6046 17.5 15.5 17.5H4.5C3.39543 17.5 2.5 16.6046 2.5 15.5V10.0648C2.5 9.49352 2.7443 8.94952 3.17127 8.56998Z"
      stroke="currentColor"
      stroke-miterlimit="10"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M7 17.5V14C7 12.3431 8.34315 11 10 11V11C11.6569 11 13 12.3431 13 14V17.5"
      stroke="currentColor"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "Home",
}
</script>
