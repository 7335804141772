<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 2.5C12.25 4 12.9421 7.21902 13 10C12.9421 12.781 12.25 16 10 17.5M10 2.5C7.75 4 7.05794 7.21903 7 10C7.05794 12.781 7.75 16 10 17.5M10 2.5C5.85786 2.5 2.5 5.85786 2.5 10M10 2.5C14.1421 2.5 17.5 5.85786 17.5 10M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10M10 17.5C5.85787 17.5 2.5 14.1421 2.5 10M17.5 10C16 12.25 12.781 12.9421 10 13C7.21903 12.9421 4 12.25 2.5 10M17.5 10C16 7.75 12.781 7.05794 10 7C7.21903 7.05794 4 7.75 2.5 10"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "Globe",
}
</script>
