<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.68343 7.33333H11.8834C12.6302 7.33333 13.0035 7.33333 13.2888 7.47866C13.5396 7.60649 13.7436 7.81046 13.8714 8.06135C14.0168 8.34656 14.0168 8.71993 14.0168 9.46667V14M8.68343 14V4.13333C8.68343 3.3866 8.68343 3.01323 8.53811 2.72801C8.41028 2.47713 8.2063 2.27316 7.95542 2.14532C7.6702 2 7.29683 2 6.5501 2H4.1501C3.40336 2 3.02999 2 2.74478 2.14532C2.49389 2.27316 2.28992 2.47713 2.16209 2.72801C2.01676 3.01323 2.01676 3.3866 2.01676 4.13333V14M14.6834 14H1.3501"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.3501 4.6665H6.3501M4.3501 7.33317H6.3501M4.3501 9.99984H6.3501"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "Organization",
}
</script>
