<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.13562 2.83629H6.5541V2.39062C6.5541 1.5622 7.22567 0.890625 8.0541 0.890625H9.94543C10.7739 0.890625 11.4454 1.5622 11.4454 2.39063V2.83629H13.8639H14.3639V2.84564H15.75C16.0261 2.84564 16.25 3.0695 16.25 3.34564C16.25 3.62178 16.0261 3.84564 15.75 3.84564H14.3639V14.6113C14.3639 15.992 13.2447 17.1113 11.8639 17.1113H6.13562C4.75491 17.1113 3.63562 15.992 3.63562 14.6113V3.84564H2.25C1.97386 3.84564 1.75 3.62178 1.75 3.34564C1.75 3.0695 1.97386 2.84564 2.25 2.84564H3.63562V2.83629H4.13562ZM4.63562 3.84564V14.6113C4.63562 15.4397 5.30719 16.1113 6.13562 16.1113H11.8639C12.6924 16.1113 13.3639 15.4397 13.3639 14.6113V3.84564H4.63562ZM10.4454 2.83629H7.5541V2.39062C7.5541 2.11448 7.77796 1.89062 8.0541 1.89062H9.94543C10.2216 1.89062 10.4454 2.11448 10.4454 2.39063V2.83629ZM7.29712 6.60372C7.57326 6.60372 7.79712 6.82757 7.79712 7.10372V12.7287C7.79712 13.0049 7.57326 13.2287 7.29712 13.2287C7.02098 13.2287 6.79712 13.0049 6.79712 12.7287V7.10372C6.79712 6.82757 7.02098 6.60372 7.29712 6.60372ZM11.2029 7.10372C11.2029 6.82757 10.979 6.60372 10.7029 6.60372C10.4267 6.60372 10.2029 6.82757 10.2029 7.10372V12.7287C10.2029 13.0049 10.4267 13.2287 10.7029 13.2287C10.979 13.2287 11.2029 13.0049 11.2029 12.7287V7.10372Z"
      fill="#525252"
    />
  </svg>
</template>
