<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2177_18725)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1562 9C16.1562 12.9523 12.9523 16.1562 9 16.1562C5.04771 16.1562 1.84375 12.9523 1.84375 9C1.84375 5.04771 5.04771 1.84375 9 1.84375C12.9523 1.84375 16.1562 5.04771 16.1562 9ZM17.1562 9C17.1562 13.5046 13.5046 17.1562 9 17.1562C4.49543 17.1562 0.84375 13.5046 0.84375 9C0.84375 4.49543 4.49543 0.84375 9 0.84375C13.5046 0.84375 17.1562 4.49543 17.1562 9ZM11.5999 10.8929C11.7972 11.0861 11.8004 11.4027 11.6071 11.5999C11.4139 11.7972 11.0973 11.8004 10.9001 11.6071L8.65004 9.40236L8.49999 9.25533L8.49999 9.04524L8.5 4.5C8.5 4.22386 8.72386 4 9 4C9.27614 4 9.5 4.22386 9.5 4.5L9.49999 8.83515L11.5999 10.8929Z"
        fill="#525252"
      />
    </g>
    <defs>
      <clipPath id="clip0_2177_18725">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
