<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.2776 8.46594C14.2776 11.6422 11.7027 14.217 8.52648 14.217C5.35024 14.217 2.77539 11.6422 2.77539 8.46594C2.77539 5.2897 5.35024 2.71484 8.52648 2.71484C11.7027 2.71484 14.2776 5.2897 14.2776 8.46594ZM12.8691 13.6351C11.6953 14.6223 10.1803 15.217 8.52648 15.217C4.79796 15.217 1.77539 12.1945 1.77539 8.46594C1.77539 4.73741 4.79796 1.71484 8.52648 1.71484C12.255 1.71484 15.2776 4.73741 15.2776 8.46594C15.2776 10.1807 14.6383 11.7461 13.5851 12.9368L14.777 14.1288L15.4847 14.8365L15.8386 15.1904L16.0155 15.3673C16.2108 15.5626 16.2108 15.8791 16.0155 16.0744C15.8203 16.2697 15.5037 16.2697 15.3084 16.0744L15.1315 15.8975L14.7776 15.5436L14.0699 14.8359L12.8691 13.6351Z"
      fill="currentColor"
    />
  </svg>
</template>
